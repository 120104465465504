#accomplishments_div {
    width: 40vw;
}

#accomplishment_items {
    margin: 0 !important;
    padding: 0 !important;
}

.accomplishment_item {
    margin: 0 15px !important;
    padding: 0 !important;
}

#accomplishment_items ul {
    list-style-type: lower-roman;
}

@media all and (max-width: 600px) {
    #accomplishments_div {
        width: 75vw;
    }
}
